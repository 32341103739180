import React from "react";

import { ModuleWrapper } from "../moduleWrapper";
import { SanityImage } from "@components/sanity/sanityImage";

const GridItem = ({ icon, title }) => {
  return (
    <div>
      {/* image */}
      <div className="w-24 mx-auto md:w-32">
        <div className="aspect-w-1 aspect-h-1 relative">
          <SanityImage
            className="absolute inset-0 object-contain"
            image={icon}
          />
        </div>
      </div>
      {/* text */}
      <h4 className="text-center font-medium blockH5 mt-one">{title}</h4>
    </div>
  );
};

const BrandGuarantees = ({ title, items, config }) => {
  return (
    <ModuleWrapper {...config}>
      <div className="bg-sand-900 px-gutter py-20 w-full lg:py-32">
        <div className="max-w-6xl mx-auto text-center">
          <h2 className="blockH2">{title}</h2>
        </div>
        {/* Grid */}
        <div className="grid grid-cols-2 gap-4 mt-16 md:grid-cols-4 max-w-7xl mx-auto">
          {items.map((item, index) => (
            <GridItem {...item} key={index} />
          ))}
        </div>
      </div>
    </ModuleWrapper>
  );
};

export default BrandGuarantees;
